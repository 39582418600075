import React from "react";
import logo from "../images/3m-logo.png";

const NavBar = () => {
    return (
        <div className="py-4 px-12 border-b flex">
            <div className="3m-logo">
                <img style={{ width: "150px" }} src={logo} alt="3M Logo" />
            </div>
            <div className="text-heading px-24 flex w-full justify-center">
                <h1>
                    <b className="whitespace-nowrap">Fleet Graphic ROI</b>
                </h1>
            </div>
        </div>
    );
};

export default NavBar;
