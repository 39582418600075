import React, { useState } from "react";
import "../../../../css/slider-style.css";
import ReactSlider from "react-slider";

const Horizontal = (props) => {

    const customTrackCss = {
        backgroundColor: "red",
        height: "10px",
        borderRadius: "10px",
    };

    const customThumbCss = {
        top: "-17px",
        height: "40px",
        width: "40px",
        backgroundColor: "white",
        borderRadius: "10px",
        borderStyle: "solid",
        borderColor: "darkgray",
        borderWidth: "2px",
        zIndex: 0
    };

    return (
        <div className="flex flex-col">
            <div className="" style={{ width: "100%" }}>

                <ReactSlider
                    invert={true}
                    className="horizontal-slider"
                    step={25}
                    value={props.value}
                    onChange={(e) => props.onChange(e, props.name)}
                    renderTrack={(props, state) => <div {...props} style={{ ...props.style, ...customTrackCss }}></div>}
                    renderThumb={(props, state) => <div {...props}
                                                        style={{ ...props.style, ...customThumbCss }}>{/*state.valueNow*/}</div>}
                />

            </div>
            <div className="flex flex-row justify-between pt-7">
                <div className="">{parseInt(props.value)}% Urban</div>
                <div className="">{100 - parseInt(props.value)}% Rural</div>
            </div>

        </div>
    );
};

export default Horizontal;
