import React, { useContext } from "react";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
    }
};

export default (props) => {
    return (<div>
        <Modal
            ariaHideApp={false}
            isOpen={props.modalIsOpen}
            onRequestClose={() => {
                props.toggleModal(false);
            }}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <button onClick={() => {
                props.toggleModal(false);
            }} className="absolute top-0 right-2 rounded-md p-4 flex justify-center">
                <p className="text-red-500 text-l">Close</p>
            </button>
            <div className="mt-10">
                {props.content()}
            </div>
        </Modal>
    </div>);
};
