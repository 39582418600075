import React from "react";
import { Range } from "react-range";
import { formatDollars } from "../../lib/helper-functions";

export const CostComparisonRow = (props) => {

    const max = 40;

    const computeColor = () => {
        const costRatio = Math.sqrt(props.cost / max);
        const c = 255 - parseInt(255 * costRatio);
        return `rgb(255,${c},${c})`;
    };

    return (
        <tr className="">
            <td className="whitespace-nowrap">{props.name}</td>
            <td className="px-5">
                <Range
                    step={0.1}
                    min={0}
                    max={max}
                    values={[props.cost]}
                    disabled={true}
                    renderTrack={({
                                      props,
                                      children
                                  }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "3px",
                                minWidth: "100px",
                                backgroundColor: "#ccc"
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "15px",
                                width: "15px",
                                borderRadius: "4px",
                                backgroundColor: `${computeColor()}`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 1px 3px #AAA"
                            }}
                        />
                    )}
                />
            </td>
            <td className="whitespace-nowrap">{formatDollars(props.cost)}</td>
        </tr>
    );
};
