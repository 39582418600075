const data = require("../data/circulation_data_1.json");

const HR_IN_DAY = 24;
const NIGHT_START = 19;
const NIGHT_END = 7;

const calcExposures = (hr_start, duration, data) => {

    // TODO: this is to accommodate what may be a bug in the mobile app
    hr_start += 1;

    let expNight, expDay;
    expNight = expDay = 0;
    for (let h = hr_start; h < hr_start + duration; h++) {
        if (h < NIGHT_END || h > NIGHT_START) {
            expNight += data[h % HR_IN_DAY];
        }
        else {
            expDay += data[h % HR_IN_DAY];
        }
    }
    return [
        expNight,
        expDay,
        expNight + expDay
    ];
};

const get_region_keys = r => {
    if (r === "West") {
        return "we";
    }
    else if (r === "Midwest") {
        return "mw";
    }
    else if (r === "South East") {
        return "se";
    }
    else if (r === "North East") {
        return "ne";
    }
    else if (r === "National") {
        return "na";
    }
    else {
        throw `region '${r}' in not supported!`;
    }
};

const get_angle_keys = a => {
    if (a === "Side") {
        return "s";
    }
    else if (a === "Rear") {
        return "r";
    }
    else {
        throw `graphic angle '${a}' in not supported!`;
    }
};

const get_roadway_weights = (input) => {
    const ruralRoadwayPercentage = 100 - input.urbanRoadwayPercentage;
    const urbanNonFreewayRoadwayPercentage = 100 - input.urbanFreewayRoadwayPercentage;
    const ruralNonFreewayRoadwayPercentage = 100 - input.ruralFreewayRoadwayPercentage;
    return {
        "rf": ((ruralRoadwayPercentage / 100.0) * (input.ruralFreewayRoadwayPercentage / 100.0)),
        "rnf": ((ruralRoadwayPercentage / 100.0) * (ruralNonFreewayRoadwayPercentage / 100.0)),
        "uf": ((input.urbanRoadwayPercentage / 100.0) * (input.urbanFreewayRoadwayPercentage / 100.0)),
        "unf": ((input.urbanRoadwayPercentage / 100.0) * (urbanNonFreewayRoadwayPercentage / 100.0))
    };
};

export const Calculator = (input) => {

    const hr_start = parseInt(input.startTime.split(":")[0]) - 1;
    const duration = parseInt(input.durationInHours);
    const region_keys = input.regions.map(get_region_keys);
    const angle_keys = input.applicationLocations.map(get_angle_keys);
    const roadway_weights = get_roadway_weights(input);

    const region_ratio = 1 / input.regions.length;

    let expNightTotal, expDayTotal, expTotal, expSideTotal, expRearTotal, dwellSideTotal, dwellRearTotal;
    expNightTotal = expDayTotal = expTotal = expSideTotal = expRearTotal = dwellSideTotal = dwellRearTotal = 0;

    Object.entries(data).map(([k, route_data]) => {

        const [region, roadway, angle] = k.split("-");

        if (region_keys.includes(region) && angle_keys.includes(angle)) {

            const roadwayWeight = roadway_weights[roadway];

            const [expNight, expDay, expNightAndDay] = calcExposures(hr_start, duration, route_data["cir_hrs"]);

            expNightTotal += (expNight * roadwayWeight);
            expDayTotal += (expDay * roadwayWeight);
            expTotal += (expNightAndDay * roadwayWeight);

            if (angle === "r") {
                expRearTotal += (expNightAndDay * roadwayWeight);
                dwellRearTotal += (route_data["dwell"] * roadwayWeight);
            }
            else if (angle === "s") {
                expSideTotal += (expNightAndDay * roadwayWeight);
                dwellSideTotal += (route_data["dwell"] * roadwayWeight);
            }
            else {
                throw `graphic angle '${angle}' in not supported!`;
            }
        }
    });

    const totalExposures = expTotal * input.numberOfVehicles * input.distanceInMiles * region_ratio;

    // Constants provided by 3M
    const trailerCostPerSide = 1750;
    const midSizeCostPerSize = 1100;
    const numberOfDays = 365;

    const sidesCovered = input.applicationLocations.length;
    const totalCPM = (totalExposures / 1000.0) * numberOfDays;
    const totalCostTrailer = (sidesCovered * trailerCostPerSide * input.numberOfVehicles) / totalCPM;
    const totalCostMidSize = (sidesCovered * midSizeCostPerSize * input.numberOfVehicles) / totalCPM;

    return {

        "ruralRoadwayPercentage": (100 - input.urbanRoadwayPercentage).toString(),
        "urbanNonFreewayRoadwayPercentage": (100 - input.urbanFreewayRoadwayPercentage).toString(),
        "ruralNonFreewayRoadwayPercentage": (100 - input.ruralFreewayRoadwayPercentage).toString(),

        "totalExposures": totalExposures,

        "sideExposures": expSideTotal * input.distanceInMiles * region_ratio,
        "rearExposures": expRearTotal * input.distanceInMiles * region_ratio,

        "nightExposures": expNightTotal * input.numberOfVehicles * input.distanceInMiles * region_ratio,
        "dayExposures": expDayTotal * input.numberOfVehicles * input.distanceInMiles * region_ratio,

        "sideDwellTime": dwellSideTotal,
        "rearDwellTime": dwellRearTotal,

        "exposuresPerVehicle": expTotal * input.distanceInMiles * 365,

        "trailerWrapCostInDollars": totalCostTrailer,
        "midSizeWrapCostInDollars": totalCostMidSize
    };
};
