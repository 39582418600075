import React, { useState } from "react";
import "../../../../css/slider-style.css";
import ReactSlider from "react-slider";

export default (props) => {

	const customTrackCss = {
		backgroundColor: 'red',
		width: '10px',
		borderRadius: "10px",
	}

	const customThumbCss = {
		left: '-15px',
		height: '40px',
		width: '40px',
		backgroundColor: 'white',
		borderRadius: "10px",
		borderStyle: "solid",
		borderColor: "darkgray",
		borderWidth: "2px",
		zIndex: 0
	}

	const justifySlider = props.hugRight ?  "justify-end" : "justify-start"

	return (
		<div>
			<div className="pb-2">{props.value}% Freeway</div>
			<div className="flex flex-col">
				<div className={`${justifySlider} flex `} style={{height: 200}}>
					<ReactSlider
						step={25}
						reverse={true}
						value={props.value}
						orientation="vertical"
						className="vertical-slider"
						thumbClassName="example-thumb"
						trackClassName="example-track"
						onChange={(e) => props.onChange(e, props.name)}
						renderTrack={(props, state) => <div {...props } style={{...props.style, ...customTrackCss}}></div>}
						renderThumb={(props, state) => <div {...props } style={{...props.style, ...customThumbCss}}>{/*state.valueNow*/}</div>}
					/>
				</div>
			</div>
			<div className="pt-2">{100 - props.value}% Non-Freeway</div>
		</div>
	);
};