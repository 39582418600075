import React, { useContext, useState, useRef } from "react";
import eye from "../../images/eye.png";
import moon from "../../images/moon.png";
import sun from "../../images/sun.png";
import truck from "../../images/truck.png";
import truckBack from "../../images/truck-back.png";
import truckSide from "../../images/truck-side.png";
import { MainContext } from "../../reducer/main-state";
import { Calculator } from "../../lib/calculator";
import { formatDollars, numberWithCommas } from "../../lib/helper-functions";
import { HelpIcon, ProjIcons } from "../shared/Icons";
import { CostComparisonRow } from "../shared/CostComparisonRow";
import CustomModal from "../shared/CustomModal";
import ReportModal from "../modals/ReportModal";
import EmailModal from "../modals/EmailModal";

export default () => {

    const {
        mainState,
        dispatch
    } = useContext(MainContext);
    const [costModalIsOpen, toggleCostModal] = useState(false);
    const [compareModalIsOpen, toggleCompareModal] = useState(false);
    const [reportModalIsOpen, toggleReportModal] = useState(false);
    const [emailModalIsOpen, toggleEmailModal] = useState(false);

    const calcOutputs = Calculator(mainState);

    return (
        <div className="h-screen px-10">

            <ReportModal
                toggleReportModal={toggleReportModal}
                toggleEmailModal={toggleEmailModal}
                reportModalIsOpen={reportModalIsOpen}
            />

            <EmailModal
                toggleEmailModal={toggleEmailModal}
                emailModalIsOpen={emailModalIsOpen}
            />

            <CustomModal
                modalIsOpen={costModalIsOpen}
                toggleModal={toggleCostModal}
                content={() => {
                    return (
                        <p className="max-w-md">Dwell time is the average number of seconds that a person will
                            be in close proximity to a graphic wrap on a fleet vehicle. As
                            you can see the rear part of a wrapped fleet vehicle has an inherently
                            greater dwell time because other vehicles typically spend more time
                            behind a wrapped vehicle rather than it's side.
                        </p>
                    );
                }}
            />

            <CustomModal
                modalIsOpen={compareModalIsOpen}
                toggleModal={toggleCompareModal}
                content={() => {
                    return (
                        <div style={{
                            height: "90vh",
                            width: "90vw"
                        }}>
                            <iframe src="/pdf/CPMComparison.pdf" type="application/pdf" width="100%" height="100%">
                            </iframe>
                        </div>
                    );
                }}
            />

            <div className="total-daily border-b flex justify-center py-4">
                <div className="eye-icon px-2">
                    <ProjIcons icon={eye} />
                </div>
                <div className="text-content">
                    <p>Total Daily Fleet Exposure</p>
                    <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.totalExposures)}</p>
                </div>
            </div>
            <div className="exposure-by-local border-b">
                <p className="px-4">Exposures By Location (Per Vehicle)</p>
                <div className="exposure-travel-content flex justify-between px-4">
                    <div className="total-daily flex justify-center py-4">
                        <div className="eye-icon">
                            <ProjIcons icon={truckSide} />
                        </div>
                        <div className="text-content px-2">
                            <p>Side</p>
                            <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.sideExposures)}</p>
                        </div>
                    </div>
                    <div className="total-daily flex justify-center py-4">
                        <div className="eye-icon px-2">
                            <ProjIcons icon={truckBack} />
                        </div>
                        <div className="text-content px-2">
                            <p>Rear</p>
                            <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.rearExposures)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="exposure-by-local border-b">
                <p className="px-4">Time of Day Exposures</p>
                <div className="exposure-travel-content flex justify-between px-4">
                    <div className="total-daily flex justify-center py-4">
                        <div className="eye-icon">
                            <ProjIcons icon={moon} />
                        </div>
                        <div className="text-content px-2">
                            <p>Night(19:00-7:00)</p>
                            <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.nightExposures)}</p>
                        </div>
                    </div>
                    <div className="total-daily flex justify-center py-4">
                        <div className="eye-icon px-2">
                            <ProjIcons icon={sun} />
                        </div>
                        <div className="text-content px-2">
                            <p>Day(7:00-19:00)</p>
                            <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.dayExposures)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="exposure-by-local border-b">
                <p className="px-4 py-4">
                    Dwell Time (Per Vehicle) <HelpIcon onClick={() => {
                    toggleCostModal(true);
                }} />
                </p>
                <div className="exposure-travel-content flex justify-between px-4">
                    <div className="total-daily flex justify-center py-4">
                        <div className="eye-icon">
                            <ProjIcons icon={truckSide} />
                        </div>
                        <div className="text-content px-2">
                            <p>Side</p>
                            <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.sideDwellTime, 2)}</p>
                        </div>
                    </div>
                    <div className="total-daily flex justify-center py-4">
                        <div className="eye-icon px-2">
                            <ProjIcons icon={truckBack} />
                        </div>
                        <div className="text-content px-2">
                            <p>Rear</p>
                            <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.rearDwellTime, 2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="annual-by-local border-b">
                <p className="px-4">Annual</p>
                <div className="total-daily border-b flex justify-center py-4">
                    <div className="eye-icon px-2">
                        <ProjIcons icon={eye} />
                    </div>
                    <div className="text-content">
                        <p>Exposure Per Vehicle</p>
                        <p className="fit-total-daily-number text-red-500">{numberWithCommas(calcOutputs.exposuresPerVehicle)}</p>
                    </div>
                </div>
                <div className="exposure-travel-content flex justify-between px-4">
                    <div className="tractor-trailer">
                        <p>Tractor Trailer</p>
                        <div className="total-daily flex justify-center py-4">
                            <div className="eye-icon">
                                <ProjIcons icon={truck} />
                            </div>
                            <div className="text-content px-2">
                                <p>Cost per CPM</p>
                                <p className="fit-total-daily-number text-red-500">{formatDollars(calcOutputs.trailerWrapCostInDollars)}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>Mid-Size Vehicle</p>
                        <div className="total-daily flex justify-center py-4">
                            <div className="eye-icon">
                                <ProjIcons icon={truck} />
                            </div>
                            <div className="text-content px-2">
                                <p>Cost per CPM</p>
                                <p className="fit-total-daily-number text-red-500">{formatDollars(calcOutputs.midSizeWrapCostInDollars)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-4 flex flex-col pb-20">
                <p className="py-5">
                    CPM Cost Comparison(Average) <HelpIcon onClick={() => {
                    toggleCompareModal(true);
                }} />
                </p>
                <table className="table-auto">
                    <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <CostComparisonRow name="Broadcast TV" cost={15} />
                    <CostComparisonRow name="Cable TV" cost={6} />
                    <CostComparisonRow name="Podcasts" cost={12} />
                    <CostComparisonRow name="Radio" cost={6} />
                    <CostComparisonRow name="Video" cost={12} />
                    <CostComparisonRow name="Mobile Display" cost={1.80} />
                    <CostComparisonRow name="Out Of Home" cost={6} />
                    <CostComparisonRow name="Newspaper" cost={35} />
                    <CostComparisonRow name="Magazines" cost={15.50} />
                    </tbody>
                </table>
            </div>

            <div className="fixed bottom-0 left-0 p-5 bg-white border-t-2 border-gray-200 w-screen text-center">

                <button onClick={() => {
                    toggleReportModal(true);
                }} className="p-5 rounded-md border-2 border-red-500 text-red-500 hover:text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 w-1/2 justify-center">
                    <p className="text-xl">Export to PDF</p>
                </button>

                <br />
                <br />

                <a
                    href="#"
                    // onClick="utag.gdpr.showDoNotSellPrompt('EN')"
                    onClick={() => {
                        utag.gdpr.showDoNotSellPrompt("EN");
                    }}
                    className="js-privacyChoice MMM--icn_privacy m-footer_legalLink"
                    title="Your Privacy Choices"
                    target="_self"
                    style={{
                        background: "url(\"/images/icn_privacyOptions.png\") no-repeat right/22px",
                        paddingRight: "24px"
                    }}
                >
                    Your Privacy Choices
                </a>

            </div>

        </div>
    );
}

