import React, { useContext, useRef, useState } from "react";
import CustomModal from "../shared/CustomModal";
import * as api from "../../lib/api";
import { MainContext } from "../../reducer/main-state";
import { Calculator } from "../../lib/calculator";
import * as helpers from "../../lib/helper-functions";

export default (props) => {

    const {
        mainState,
        dispatch
    } = useContext(MainContext);
    const [isSendingEmail, toggleIsSendingEmail] = useState(false);
    const [emailStatus, setEmailStatus] = useState(null);
    const [recipientEmail, setRecipientEmail] = useState("");
    const [statusTextColor, setStatusTextColor] = useState("");
    const calcOutputs = Calculator(mainState);

    const sendEmail = () => {
        if (recipientEmail && helpers.validateEmail(recipientEmail)) {
            toggleIsSendingEmail(true);
            api.sendEmail(mainState, calcOutputs, recipientEmail)
                .then((response) => {
                    setEmailStatus(response.data.message);
                    setStatusTextColor("text-green-500");
                })
                .catch((err) => {
                    err.response.data.message ?
                        setEmailStatus(err.response.data.message) :
                        setEmailStatus("Internal Server Error");
                    setStatusTextColor("text-red-500");
                })
                .finally(() => {
                    toggleIsSendingEmail(false);
                });
        }
        else {
            setStatusTextColor("text-red-500");
            setEmailStatus("Please enter a valid email");
        }
    };

    const renderSendEmailButton = () => {
        if (isSendingEmail) {
            return (
                <button disabled className="p-2 mr-5 rounded-md border-2  border-red-500 w-full flex justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3 " viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <p className="text-red-500 text-l">Processing</p>
                </button>
            );
        }
        else {
            return (
                <button onClick={sendEmail} className="p-2 mr-5 rounded-md bg-white border-2
                                                        border-red-500 text-red-500 hover:text-white hover:bg-red-500
                                                        focus:outline-none focus:ring-2 focus:ring-red-500 w-full
                                                        flex justify-center">
                    <p className="text-l whitespace-nowrap">Send Email</p>
                </button>
            );
        }
    };

    const renderEmailStatus = () => {
        if (!emailStatus)
            return <div></div>;
        else
            return <p className={`text-l whitespace-nowrap mb-10 ${statusTextColor}`}>
                {emailStatus}
            </p>;
    };

    return (
        <CustomModal
            modalIsOpen={props.emailModalIsOpen}
            toggleModal={props.toggleEmailModal}
            content={() => {
                return (
                    <CustomModal
                        modalIsOpen={props.emailModalIsOpen}
                        toggleModal={() => {
                            props.toggleEmailModal(false), setEmailStatus("");
                        }}
                        content={() =>
                            <div className="flex flex-col bg-gray-100 justify-center items-center" style={{ width: "80vw" }}>

                                <div className="flex-1 grid grid-cols-1 md:grid-cols-12 w-full md:mt-0 mt-10">

                                    <div className="col-span-1 md:col-span-3 flex justify-center items-center md:mt-0">
                                        <p className="whitespace-nowrap">Email Recipient:</p>
                                    </div>

                                    <div className="col-span-1 md:col-span-6 flex justify-center items-center p-5">
                                        <input
                                            type="text"
                                            name={"clientName"}
                                            onChange={(e) => setRecipientEmail(e.target.value)}
                                            className="block rounded-md border-2 p-2 h-10 w-full"
                                            placeholder="client@org.com"
                                            value={recipientEmail}
                                        />
                                    </div>

                                    <div className="col-span-1 md:col-span-3 flex justify-center items-center md:mb-0 mb-10">
                                        <div>{renderSendEmailButton()}</div>
                                    </div>

                                </div>

                                {renderEmailStatus()}

                            </div>
                        }
                    />
                );
            }}
        />
    );
}
