import React from "react";

export const ProjIcons = (props) => {
    return (<span>
            <img
                style={{
                    width: "45px",
                    height: "auto"
                }}
                src={props.icon}
            />
        </span>);
};

export const HelpIcon = (props) => {
    return (<button style={{
        height: "1.5rem",
        width: "1.5rem",
        borderRadius: "50%"
    }}
        className="border border-red-400 text-center text-red-500 inline-block"
        onClick={props.onClick}
    >
        i
    </button>);
};
