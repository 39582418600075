import React, { useContext } from "react";
import { MainContext } from "../../reducer/main-state";
import InputPane from "./InputPane";
import OutputPane from "./OutputPane";

const Main = () => {

    const {
        mainState,
        dispatch
    } = useContext(MainContext);

    console.log(mainState);

    return (
        <div className="grid md:grid-cols-2 grid-cols-1" style={{ marginBottom: 150 }}>

            <div className="border-r">
                <InputPane />
            </div>
            <div>
                <OutputPane />
            </div>
        </div>
    );
};

export default Main;
