import axios from "axios";

const parameterizeBody = (state, outputs) => ({
    "sideExposures": parseInt(outputs.sideExposures),
    "sideDwellTime": outputs.sideDwellTime.toFixed(2),
    "clientName": state.clientName.toString(),
    "urbanFreewayRoadwayPercentage": state.urbanFreewayRoadwayPercentage.toString(),
    "numberOfVehicles": parseInt(state.numberOfVehicles),
    "urbanNonFreewayRoadwayPercentage": outputs.urbanNonFreewayRoadwayPercentage.toString(),
    "ruralNonFreewayRoadwayPercentage": outputs.ruralNonFreewayRoadwayPercentage.toString(),
    "dayExposures": parseInt(outputs.dayExposures),
    "durationInHours": parseInt(state.durationInHours),
    "exposuresPerVehicle": parseInt(outputs.exposuresPerVehicle),
    "distanceInMiles": parseInt(state.distanceInMiles),
    "rearExposures": parseInt(outputs.rearExposures),
    "rearDwellTime": outputs.rearDwellTime.toFixed(2),
    "totalExposures": parseInt(outputs.totalExposures),
    "startTime": state.startTime.split(":").slice(0, 2).join(":"),
    "ruralFreewayRoadwayPercentage": state.ruralFreewayRoadwayPercentage.toString(),
    "salesRep": state.salesRep.toString(),
    "nightExposures": parseInt(outputs.nightExposures),
    "urbanRoadwayPercentage": state.urbanRoadwayPercentage.toString(),
    "ruralRoadwayPercentage": outputs.ruralRoadwayPercentage.toString(),

    "midSizeWrapCostInDollars": 2200,
    "trailerWrapCostInDollars": 3500,

    "applicationLocations": state.applicationLocations,
    "regions": state.regions,

    "distanceInKilometers": parseInt(state.distanceInMiles * 1.60934),
    "distanceUnits": "mi",
    "isDST": false
});

export const sendEmail = (state, outputs, email) => {

    const url = `/api/v1/send_email`;
    const body = parameterizeBody(state, outputs);
    const data = {
        headers: { "Content-Type": "application/json" }
    };

    return axios.post(url, {
        app_meta: body,
        email: email
    }, data);
};

export const getPDF = (state, outputs) => {

    const url = `/api/v1/render_report.pdf`;
    let body = parameterizeBody(state, outputs);

    const data = {
        headers: { "Content-Type": "application/json" },
        responseType: "blob"
    };

    return axios.post(url, body, data);
};

export const getPDFLink = (state, outputs) => {

    const url = `/api/v1/render_report.pdf`;

    let params = parameterizeBody(state, outputs);
    params["reportIsForModal"] = true;

    return `${url}?${Object.keys(params).map((k, i) => `${k}=${params[k]}`).join("&")}`;
};
