import React, { useContext } from "react";

export default (props) => {

    const twStyles = {
        activeDivStyle: "bg-red-500",
        normalDivStyle: "bg-gray-200 text-black"
    };

    return (
        <div
            name={props.text}
            onClick={props.onClick}
            className={`${props.isActive ? twStyles.activeDivStyle : twStyles.normalDivStyle} px-4 py-4 text-center m-1 cursor-default`}
        >
            {props.text}
        </div>
    );
}
