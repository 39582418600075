import { createContext } from "react";

export const initialState = {

    "clientName": "",
    "salesRep": "",

    "startTime": "09:00:00.00",
    "durationInHours": 6,
    "distanceInMiles": 360,
    "numberOfVehicles": 1,

    "urbanRoadwayPercentage": 50,
    "urbanFreewayRoadwayPercentage": 50,
    "ruralFreewayRoadwayPercentage": 50,

    "regions": ["Midwest"],

    "applicationLocations": [
        "Rear",
        "Side"
    ]
};

export const reducer = (state, action) => {
    switch (action.type) {
        case "set":
            return {
                ...state,
                [action.event.target.name]: action.event.target.value
            };
        case "setSlider":
            return {
                ...state,
                [action.key]: action.event
            };
        case "setRegion":

            const focusedRegion = action.event.target.getAttribute("name");
            const regionAlreadySelected = state.regions.includes(focusedRegion);

            // at least one region must be selected
            if (regionAlreadySelected && state.regions.length > 1)
                return {
                    ...state,
                    regions: state.regions.filter(e => e !== focusedRegion)
                };
            else if (!regionAlreadySelected)
                return {
                    ...state,
                    regions: [
                        ...state.regions,
                        focusedRegion
                    ]
                };
            else
                return state;

        case "setApplicationLocation":

            const focusedLocation = action.event.target.getAttribute("name");
            const locationAlreadySelected = state.applicationLocations.includes(focusedLocation);

            // at least one location must be selected
            if (locationAlreadySelected && state.applicationLocations.length > 1)
                return {
                    ...state,
                    applicationLocations: state.applicationLocations.filter(e => e !== focusedLocation)
                };
            else if (!locationAlreadySelected)
                return {
                    ...state,
                    applicationLocations: [
                        ...state.applicationLocations,
                        focusedLocation
                    ]
                };
            else
                return state;

        case "setInt":
            const value = parseInt(action.event.target.value);
            if (isNaN(value))
                return {
                    ...state,
                    [action.event.target.name]: value
                };
            else if (value >= action.range.min && value <= action.range.max)
                return {
                    ...state,
                    [action.event.target.name]: value
                };
            else
                return state;
        case "reset":
            return initialState;
        default:
            return state;
    }
};

export const MainContext = createContext(initialState);
