export const formatDollars = (num) => {
    return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
    });
};

export const numberWithCommas = (x, fractionDigits = 0) => {
    return x.toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPercent = (num) => {
    return `${Math.round((num + Number.EPSILON) * 100)}%`;
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
