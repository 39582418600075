import React, { useContext, useRef } from "react";
import spinner from "../../images/25.gif";
import * as api from "../../lib/api";
import CustomModal from "../shared/CustomModal";
import download from "downloadjs";
import { MainContext } from "../../reducer/main-state";
import { Calculator } from "../../lib/calculator";

export default (props) => {

    const {
        mainState,
        dispatch
    } = useContext(MainContext);
    const shareButtonDropdownRef = useRef();
    const calcOutputs = Calculator(mainState);

    const getReportPDFName = () => {
        return `3M Fleet ROI Calculator${mainState.clientName && " - "}${mainState.clientName}`;
    };

    const downloadReport = () => {
        api.getPDF(mainState, calcOutputs).then((response) => {
            download(response.data, getReportPDFName(), "application/pdf");
        });
    };

    const toggleShareDropdown = () => {
        let classList = shareButtonDropdownRef.current.classList;

        if (classList.contains("hidden")) {
            classList.remove("hidden");
            classList.add("block");
        }
        else {
            classList.remove("block");
            classList.add("hidden");
        }
    };

    return (
        <CustomModal
            modalIsOpen={props.reportModalIsOpen}
            toggleModal={props.toggleReportModal}
            content={() => {
                return (
                    <div className="flex justify-center align-middle" style={{
                        height: "70vh",
                        width: "80vw"
                    }}>

                        <div className="flex justify-around">
                            <img width="25" height="25" style={{ objectFit: "contain" }} id="reportSpinner" src={spinner} />
                        </div>

                        <div className="absolute top-0 left-0 p-4">

                            <div className="dropdown inline-block relative">
                                <button
                                    onClick={toggleShareDropdown}
                                    className="text-red-500 text-l rounded px-4 inline-flex items-center">
                                    <span className="mr-1">Share</span>
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20">
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </button>
                                <ul ref={shareButtonDropdownRef} className="dropdown-menu absolute hidden bg-white rounded text-red-500 text-l pt-1 border border-2 mt-5">
                                    <li className="">
                                        <button onClick={() => {
                                            props.toggleReportModal(false);
                                            props.toggleEmailModal(true);
                                        }} className="rounded-t px-6 py-4 hover-bg-black flex justify-center">
                                            <p className="">Email</p>
                                        </button>
                                    </li>
                                    <li className="">
                                        <button onClick={() => {
                                            downloadReport();
                                        }} className="px-6 py-4 flex justify-center">
                                            <p className="">Download</p>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <iframe
                            id="reportFrame"
                            src={api.getPDFLink(mainState, calcOutputs)}
                            style={{ display: "none" }}
                            onLoad={() => {
                                document.getElementById("reportFrame").style.display = "block";
                                document.getElementById("reportSpinner").style.display = "none";
                            }}
                            type="application/pdf" width="100%" height="100%">
                        </iframe>

                    </div>
                );
            }}
        />
    );
}
