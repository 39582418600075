import React, { useState, useContext } from "react";
import RangeSliderMain from "../shared/RangeSliderMain";
import VerticalSlider from "../shared/RangeSliderVertical";
import { MainContext } from "../../reducer/main-state";
import CustomButton from "../shared/CustomButton";

export default () => {

    const {
        mainState,
        dispatch
    } = useContext(MainContext);

    const onChangeApplicationLocation = (e) => {
        dispatch({
            type: "setApplicationLocation",
            event: e
        });
    };

    const isApplicationLocationActive = (applicationLocStr) => {
        return mainState.applicationLocations.includes(applicationLocStr);
    };

    const onChangeRegion = (e) => {
        dispatch({
            type: "setRegion",
            event: e
        });
    };

    const isRegionActive = (regionStr) => {
        return mainState.regions.includes(regionStr);
    };

    const onChangeSlider = (e, k) => {
        dispatch({
            type: "setSlider",
            event: e,
            key: k
        });
    };

    const onChangeText = (e) => {
        dispatch({
            type: "set",
            event: e
        });
    };

    const onChangeInt = (e, range = {
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
    }) => {
        dispatch({
            type: "setInt",
            range: range,
            event: e
        });
    };

    const onClick = () => {
        dispatch({ type: "reset" });
    };

    return (
        <div className="px-10">
            <div className="flex py-4 justify-between">
                <p className="font-semibold w-1/2">Customer Info</p>
                <button onClick={onClick}>
                    <p className="text-red-500">Reset</p>
                </button>
            </div>

            <div className="flex flex-wrap justify-evenly bg-gray-100 px-8">
                <div className="flex py-2">
                    <p className="whitespace-nowrap">Client:</p>
                    <input
                        type="text"
                        name={"clientName"}
                        onChange={onChangeText}
                        value={mainState.clientName}
                        className="block bg-gray-200 rounded-md mx-2 py-1 px-2"
                        placeholder="Target, Best Buy"
                    />
                </div>
                <div className="flex py-2">
                    <p className="whitespace-nowrap">Sales Rep:</p>
                    <input
                        type="text"
                        name={"salesRep"}
                        value={mainState.salesRep}
                        onChange={onChangeText}
                        className="block bg-gray-200 rounded-md mx-2 py-1 px-2"
                        placeholder="John Doe"
                    />
                </div>
            </div>

            <div className="flex py-4 justify-start px-8">
                <p className="font-semibold w-1/2">Travel Info</p>
            </div>

            <div className="time-inputs flex flex-wrap justify-evenly bg-gray-100 ">
                <div className="flex py-2 px-5">
                    <p>Start:</p>
                    <input
                        type="time"
                        name={"startTime"}
                        onChange={onChangeText}
                        value={mainState.startTime}
                        className="block bg-gray-200 rounded-md mx-2 py-1 px-2"
                    />
                </div>
                <div className="flex py-2">
                    <p>Duration(hrs):</p>
                    <input
                        type="number"
                        name={"durationInHours"}
                        value={mainState.durationInHours}
                        className="block bg-gray-200 rounded-md mx-2 py-1 px-2"
                        onChange={(e) => {
                            onChangeInt(e, {
                                min: 1,
                                max: Number.MAX_SAFE_INTEGER
                            });
                        }}
                    />
                </div>
                <div className="flex py-2">
                    <span>Distance(mi):</span>
                    <input
                        type="number"
                        name={"distanceInMiles"}
                        className="block bg-gray-200 rounded-md mx-2 py-1 px-2"
                        value={mainState.distanceInMiles}
                        onChange={onChangeText}
                        onChange={(e) => {
                            onChangeInt(e, {
                                min: 1,
                                max: Number.MAX_SAFE_INTEGER
                            });
                        }}
                    />
                </div>
                <div className="flex py-2">
                    <span>Fleet Size:</span>
                    <input
                        type="number"
                        name="numberOfVehicles"
                        value={mainState.numberOfVehicles}
                        className="block bg-gray-200 rounded-md mx-2 py-1 px-2"
                        onChange={(e) => {
                            onChangeInt(e, {
                                min: 1,
                                max: Number.MAX_SAFE_INTEGER
                            });
                        }}
                    />
                </div>
            </div>
            <div className="travel-info py-4 justify-end px-8">
                <p className="font-semibold w-1/2 py-4">Route Info</p>

                <RangeSliderMain
                    name={"urbanRoadwayPercentage"}
                    value={mainState.urbanRoadwayPercentage}
                    onChange={onChangeSlider}
                />

                <div className="r-container py-4 my-4">
                    <div
                        style={{ height: "250px" }}
                        className="slider-vertical flex justify-between py-2 px-2"
                    >
                        <VerticalSlider
                            name={"urbanFreewayRoadwayPercentage"}
                            value={mainState.urbanFreewayRoadwayPercentage}
                            onChange={onChangeSlider}
                        />

                        <VerticalSlider
                            name={"ruralFreewayRoadwayPercentage"}
                            value={mainState.ruralFreewayRoadwayPercentage}
                            onChange={onChangeSlider}
                            hugRight
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 justify-end px-8 ">
                <p className="font-semibold w-1/2 py-4">Regional Traveled</p>
                <div className="w-full flex flex-wrap text-gray-100">

                    <CustomButton
                        text={"West"}
                        onClick={onChangeRegion}
                        isActive={isRegionActive("West")}
                    />

                    <CustomButton
                        text={"Midwest"}
                        onClick={onChangeRegion}
                        isActive={isRegionActive("Midwest")}
                    />

                    <CustomButton
                        text={"South East"}
                        onClick={onChangeRegion}
                        isActive={isRegionActive("South East")}
                    />

                    <CustomButton
                        text={"North East"}
                        onClick={onChangeRegion}
                        isActive={isRegionActive("North East")}
                    />

                    <CustomButton
                        text={"National"}
                        onClick={onChangeRegion}
                        isActive={isRegionActive("National")}
                    />
                </div>
            </div>

            <div className="graphic-application regional-travel-info py-4 justify-end px-8">
                <p className="font-semibold w-1/2 py-4 whitespace-nowrap">Graphic Application</p>
                <div className="travel-regions w-full flex justify-center text-gray-100">
                    <div className="w-full"></div>

                    <CustomButton
                        text={"Side"}
                        onClick={onChangeApplicationLocation}
                        isActive={isApplicationLocationActive("Side")}
                    />

                    <CustomButton
                        text={"Rear"}
                        onClick={onChangeApplicationLocation}
                        isActive={isApplicationLocationActive("Rear")}
                    />

                    <div className="w-full"></div>
                </div>
            </div>
        </div>
    );
}
