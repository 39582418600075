import React, { useReducer } from "react";
import { MainContext, initialState, reducer } from "./reducer/main-state";
import NavBar from "./components/NavBar";
import Main from "./components/main/Main";

import "./styles/built-tailwind.css";

const FleetCalcApp = () => {

    const [mainState, dispatch] = useReducer(reducer, initialState);

    return (
        <MainContext.Provider value={{
            mainState,
            dispatch
        }}>
            <div className="">
                <NavBar />
                <Main />
            </div>
        </MainContext.Provider>
    );
};

export default FleetCalcApp;
