import React from "react";

const RangeSliderRight = () => {
	return (
		<div class="flex w-full m-auto items-center py-2 justify-center px-2">
			<div class="py-1 relative min-w-full">
				<div class="h-2 bg-red-500 rounded-full">
					<div
						class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-gray-100 shadow border border-gray-300 -ml-2 top-0 cursor-pointer"
						unselectable="on"
						onselectstart="return false;"
						style={{ left: "50%" }}
					>
						<div class="relative -mt-2 w-1">
							<div
								class="absolute z-40 opacity-100 bottom-100 mb-2 left-0 min-w-full"
								style={{ marginLeft: "-20.5px" }}
							>
								<div class="relative shadow-md">
									<div class="bg-red-500 -mt-8 text-white truncate text-xs rounded py-1 px-4">
										50
									</div>
									<svg
										class="absolute text-red-500 w-full h-2 left-100 top-0"
										x="0px"
										y="0px"
										viewBox="0 0 255 255"
										// xml:space="preserve"
									>
										<polygon
											class="fill-current"
											points="0,0 127.5,127.5 255,0"
										></polygon>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div class="absolute text-gray-900 -ml-1 bottom-0 left-0 -mb-6">
						Urban 50%
					</div>
					<div class="absolute text-gray-900 -mr-1 bottom-0 right-0 -mb-6">
						Rural 50%
					</div>
				</div>
			</div>
		</div>
	);
};

export default RangeSliderRight;
